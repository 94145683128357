import axios from 'axios'

const api = 'tribus'

const Tribus = {
    crearTribu(payload) {
        return axios.post(`${api}`,payload)
    },
    editarTribu(payload) {
        return axios.post(`${api}/${payload.id}`,payload)
    },
    listarTribus() {
        return axios.get(`${api}`)
    },
    cambiarEstadoTribu(id) {
        return axios.put(`${api}/${id}/estado`)
    },
    eliminarTribu(id) {
        return axios.delete(`${api}/${id}`)
    },
    detalleTribu(id){
        return axios.get(`${api}/${id}/detalle`)
    },
    agregarIntegrante(payload, idTribu) {
        return axios.post(`${api}/${idTribu}/integrantes`,payload)
    },
    editarIntegrante(payload, idTribu, idIntegrante) {
        return axios.put(`${api}/${idTribu}/integrantes/${idIntegrante}`,payload)
    },
    asignarLider(idTribu, idIntegrante) {
        return axios.put(`${api}/${idTribu}/integrantes/${idIntegrante}/lider`)
    },
    cambiarEstadoIntegrante(idTribu, idIntegrante) {
        return axios.put(`${api}/${idTribu}/integrantes/${idIntegrante}/estado`)
    },
    eliminarIntegrante(idTribu, idIntegrante) {
        return axios.delete(`${api}/${idTribu}/integrantes/${idIntegrante}`)
    },
}

export default Tribus