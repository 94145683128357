<template>
    <modal ref="modalAgregarParticipante" :titulo="`${titulo} PARTICIPANTE`"  no-aceptar :adicional="btnModal" @adicional="handleSave">
        <div class="row mx-0 j-center">
            <ValidationObserver ref="formAnnadirIntegrante">
                <div class="col-11">
                    <div class="my-4">
                        <p class="f-14 fk-medium">Nombre</p>
                        <ValidationProvider v-slot="{ errors }" name="nombre" rules="required|max:60">
                            <el-input v-model="model.nombre" class="w-100" placeholder="Nombre" />
                            <p class="v-error w-100 f-11"> {{ errors[0] }} </p>
                        </ValidationProvider>
                    </div>
                    <div class="my-4">
                        <p class="f-14 fk-medium">Cargo</p>
                        <ValidationProvider v-slot="{ errors }" name="cargo" rules="required|max:15">
                            <el-input v-model="model.cargo" class="w-100" placeholder="Cargo" />
                            <p class="v-error w-100 f-11"> {{ errors[0] }} </p>
                        </ValidationProvider>
                    </div>
                    <div>
                        <el-checkbox v-model="model.lider">
                            Seleccionar participante como líder de la tribu
                        </el-checkbox>
                    </div>
                </div>
            </ValidationObserver>
        </div>
    </modal>
</template>

<script>
import Tribus from '~/services/tribus/tribus'
export default {
    data() {
        return {
            model: {
                id: null,
                nombre: '',
                cargo: '',
                lider: 0,
            },
            titulo:'AÑADIR',
            btnModal:'AÑADIR',
            idIntegrante:null
        }
    },
    methods: {
        toggle(item) {
            this.reset()
            if (item) {
                this.idIntegrante = item.id
                this.titulo = 'EDITAR'
                this.btnModal = 'GUARDAR'
                this.model = {
                    nombre: item.nombre,
                    cargo: item.cargo,
                    lider: item.lider,
                }
            }
            this.$refs.modalAgregarParticipante.toggle();
        },
        async handleSave() {
            try {
                const valid = await this.$refs.formAnnadirIntegrante.validate()
                if(!valid) return
                this.model.lider = this.model.lider ? 1 : 0
                if(this.idIntegrante == null) await Tribus.agregarIntegrante(this.model, this.$route.params.id_tribu);
                else await Tribus.editarIntegrante(this.model, this.$route.params.id_tribu, this.idIntegrante);
                this.$emit('detalleTribu')
            } catch (error) {
                const mensaje = error?.response?.data?.error ?? 'error al crear tribu'
                return this.notificacion('Error', mensaje, 'error')
            }
            this.$refs.modalAgregarParticipante.toggle();
        },
        reset() {
            this.model = {
                nombre: '',
                cargo: '',
                lider: false,
            }
            this.titulo = 'AÑADIR'
            this.btnModal = 'AÑADIR'
            this.idIntegrante = null
        }
    }
}
</script>