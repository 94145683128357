<template>
    <section class="tribe-details container-fluid px-5">
        <div class="row mx-0">
            <div class="col-auto my-auto">
                <div class="btn-grey font-medium-italic px-3 f-300 w-178px f-18 h-32px" @click="goBack">
                    Regresar
                </div>
            </div>
        </div>
        <!--  -->
        <div class="row mx-0 mt-4">
            <div class="col-10 py-2 py-4">
                <div class="row mx-0">
                    <div class="col-auto col-lg-5 my-auto cr-pointer">
                        <div class="d-middle">
                            <img :src="tribu.imagen" alt="" class="wf-122px bg-inactive h-122px mr-3" />
                            <div class="ml-3">
                                <div class="d-middle">
                                    <div class="inclinacion w-45px h-55px text-white text-center font-bold-italic f-36" :style="`background-color: ${tribu.color}`">
                                        {{ posicion }}°
                                    </div>
                                    <p class="f-50 font-bold-italic ml-2">{{ tribu.nombre }}</p>
                                </div>
                                <p class="f-37 text-red font-black-italic ml-5"> {{ tribu.total_puntos != null ? tribu.total_puntos : 0 }} pts.</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-auto my-auto">
                        <div class="d-middle text-grey-light fk-regular">
                            <i class="icon-account-circle-outline f-24" />
                            <p class="f-17 ml-1"> {{ tribu.participantes }} Participantes</p>
                        </div>
                    </div>
                    <div class="col-auto my-auto d-flex text-general ml-auto">
                        <el-switch v-model="tribu.estado" active-color="#29D884" inactive-color="#DFE1E6" :active-value="1" :inactive-value="0" @change="cambiarEstadoTribu"/>
                    </div>
                    <div class="col-auto my-auto d-middle">
                        <el-tooltip effect="light" content="Agregar participante" placement="bottom">
                            <div class="btn-general wf-47px mx-2" @click="openModalAddMember">
                                <i class="icon-plus" />
                            </div>
                        </el-tooltip>
                        <el-tooltip effect="light" content="Editar tribu" placement="bottom">
                            <div class="btn-grey mx-2 wf-47px" @click="editTribe()">
                                <i class="icon-pencil-outline f-15" />
                            </div>
                        </el-tooltip>
                        <el-tooltip effect="light" content="Eliminar tribu" placement="bottom">
                            <div class="btn-grey mx-2 wf-47px" @click="openModalDeleteTribe()">
                                <i class="icon-icon-trash-outline f-15" />
                            </div>
                        </el-tooltip>
                    </div>
                    <!-- <div class="row mx-0 a-center" /> -->
                </div>
            </div>
        </div>
        <!--  -->
        <div class="row mx-4 mt-4">
            <div class="col-9">
                <div v-for="(item, index) in integrantes" :key="index" class="row mx-0 mt-4 bg-fondo inclinacion">
                    <div class="col-6 py-2   ml-2 py-2">
                        <div class="row mx-0">
                            <div class="col-auto">
                                <div :class="`${item.estado == 0 ? 'text-grey-light' : ''} d-middle`">
                                    <p class="f-18 fk-regular">{{ item.nombre }} - {{item. cargo}}</p>
                                    <i v-if="item.estado == 0" class="icon-user-inactive ml-2" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="item.lider" class="col-auto d-middle">
                        <i class="icon-check" />
                        <p>Lider</p>
                    </div>
                    <div class="col-auto my-auto ml-auto">
                        <!-- <el-tooltip effect="light" content="Agregar participante" placement="bottom">
                        </el-tooltip> -->
                        <el-dropdown>
                            <span class="el-dropdown-link">
                                <div class="btn-grey wf-47px mx-2">
                                    <i class="icon-dots-vertical" />
                                </div>
                            </span>
                            <el-dropdown-menu slot="dropdown">
                                <el-dropdown-item>
                                    <p @click="openChangeState(item)"> {{ item.estado == 1 ? 'Inactivar' : 'Activar'}}</p>
                                </el-dropdown-item>
                                <el-dropdown-item>
                                    <p @click="openAsignarLider(item)">Asignar como lider</p>
                                </el-dropdown-item>
                                <el-dropdown-item>
                                    <p @click="openEditMember(item)">Editar</p>
                                </el-dropdown-item>
                                <el-dropdown-item >
                                    <p @click="openModalDeleteMember(item.id)">Eliminar</p>
                                </el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown>
                    </div>
                </div>
            </div>
        </div>
        <modal-agregar-participante @detalleTribu="detalleTribu" ref="refModalAgregarParticipante" />
        <modal-eliminar-participante @eliminarIntegrante="eliminarIntegrante" ref="refModalEliminarParticipante" />
        <modal-eliminar-tribu @eliminarTribu="eliminarTribu" ref="refModalEliminarTribu" />
        <modal-crear-tribu @listarTribus="detalleTribu" ref="refModalCrearTribu" />
    </section>
</template>
<script>
import modalAgregarParticipante from './partials/modalAgregarParticipante.vue'
import modalEliminarParticipante from './partials/modalEliminarParticipante.vue'
import modalEliminarTribu from './partials/modalEliminarTribu.vue'
import modalCrearTribu from './partials/modalCrearTribu.vue'
import Tribus from '~/services/tribus/tribus'
export default {
    components: {
        modalAgregarParticipante,
        modalEliminarParticipante,
        modalEliminarTribu,
        modalCrearTribu
    },
    data() {
        return {
            tribu: {},
            integrantes: [],
            posicion:this.$route.params.posicion,
            idIntegrante:null
        }
    },
    mounted() {
        this.detalleTribu()
    },
    methods: {
        openModalAddMember() {
            this.$refs.refModalAgregarParticipante.toggle()
        },
        openEditMember(item) {
            this.$refs.refModalAgregarParticipante.toggle(item)
        },
        openModalDeleteMember(idIntegrante) {
            this.idIntegrante = idIntegrante
            this.$refs.refModalEliminarParticipante.toggle()
        },
        openModalDeleteTribe() {
            this.$refs.refModalEliminarTribu.toggle()
        },
        editTribe() {
            this.$refs.refModalCrearTribu.toggle(this.tribu)
        },
        async eliminarIntegrante(){
            try {
                const { data } = await Tribus.eliminarIntegrante(this.$route.params.id_tribu, this.idIntegrante);
                if (data?.exito) {
                    this.notificacion('Mensaje', "eliminado correctamente", 'success')
                    this.integrantes = this.integrantes.filter(el => el.id != this.idIntegrante)
                }
                this.idIntegrante = null
            } catch (error) {
                const mensaje = error?.response?.data?.error ?? 'error al eliminar integrante'
                this.notificacion('Error', mensaje, 'error')
            }
        },
        async openChangeState(item) {
            try {
                await Tribus.cambiarEstadoIntegrante(this.$route.params.id_tribu, item.id)
                this.detalleTribu()
            } catch (error) {
                const mensaje = error?.response?.data?.error ?? 'error al cambiar estado'
                this.notificacion('Error', mensaje, 'error')
            }
        },
        async openAsignarLider(item) {
            try {
                await Tribus.asignarLider(this.$route.params.id_tribu, item.id)
                this.detalleTribu()
            } catch (error) {
                const mensaje = error?.response?.data?.error ?? 'error al asigtnar como lider'
                this.notificacion('Error', mensaje, 'error')
            }
        },
        goBack() {
            this.$router.push({name: 'admin.tribus'});
        },
        async detalleTribu(){
            try {
                const { data } = await Tribus.detalleTribu(this.$route.params.id_tribu)
                this.tribu = data?.data?.tribu ?? {}
                this.integrantes = data?.data?.integrantes ?? []
            } catch (error) {
                const mensaje = error?.response?.data?.error ?? 'error al cambiar estado'
                this.notificacion('Error', mensaje, 'error')
            }
        },
        async cambiarEstadoTribu(){
            try {
                await Tribus.cambiarEstadoTribu(this.$route.params.id_tribu);
                this.notificacion('Mensaje', 'Actualizado correctamente', 'success')
            } catch (error) {
                this.listarTribus()
                const mensaje = error?.response?.data?.error ?? 'error al cambiar estado'
                this.notificacion('Error', mensaje, 'error')
            }
        },
        async eliminarTribu(){
            try {
                const { data } = await Tribus.eliminarTribu(this.tribu.id);
                if (data?.exito) {
                    this.notificacion('Mensaje', "eliminado correctamente", 'success')
                    this.$router.push({name: 'admin.tribus'});
                }
                this.idTribu = null
            } catch (error) {
                const mensaje = error?.response?.data?.mensaje ?? 'error al eliminar tribus'
                this.notificacion('Error', mensaje, 'error')
            }
        },
    },
}
</script>
<style lang="scss" scoped>
    .tribe-details{
        .text-grey-light{
            color: #637381;
        }
    }
</style>