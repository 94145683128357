<template>
    <modal ref="modalCreateTribe" :titulo="`${titulo} TRIBU`" tamano="modal-md" no-aceptar :adicional="btnModal" @adicional="handleSave">
        <ValidationObserver ref="formCrearTribu">
            <div class="row mx-0">
                <div class="col-auto">
                    <p class="f-14 fk-medium text-center mb-2">Logo de la tribu</p>
                    <slim-cropper ref="fotoTribu" :options="slimOptions" class="border cr-pointer wf-112px h-112px bg-f5 rounded-circle" />
                </div>
                <div class="col">
                    <div class="mb-4">
                        <p class="f-14 fk-medium">Nombre</p>
                        <ValidationProvider v-slot="{ errors }" name="nombre" rules="required|max:70">
                            <el-input v-model="model.nombre" class="w-100" placeholder="Nombre" />
                            <p class="v-error w-100 f-11"> {{ errors[0] }} </p>
                        </ValidationProvider>
                    </div>
                    <div class="mb-4">
                        <p class="f-14 fk-medium">Contraseña</p>
                        <ValidationProvider v-slot="{ errors }" name="contraseña" :rules="{required:model.id == null, min:6, max:15}">
                            <el-input v-model="model.clave" class="w-100" placeholder="Contraseña" />
                            <p class="v-error w-100 f-11"> {{ errors[0] }} </p>
                        </ValidationProvider>
                    </div>
                </div>
            </div>
            <div class="row mx-0">
                <div class="col-12">
                    <p class="f-14 fk-medium pl-3 mb-2">Selecciona un color para la tribu</p>
                    <div class="d-middle" style="gap:0.25rem">
                        <div v-for="(data, index) in colors" :key="index" class="d-middle-center wh-32 cr-pointer" :style="`background-color: ${data}`" @click="getColor(data)">
                            <i :class="`${ model.color == data ? 'icon-check' : '' } `" />
                        </div>
                    </div>
                    <p v-if="model.color == ''" class="f-11" style="color:red">El campo color es obligatorio</p>
                </div>
            </div>
        </ValidationObserver>
    </modal>
</template>

<script>
import Tribus from '~/services/tribus/tribus'
export default {
    data() {
        return {
            titulo:'CREAR',
            btnModal:'CREAR',
            model: {
                id:null,
                imagen: '',
                nombre: '',
                clave: '',
                color: '',
            },
            slimOptions: {
                ratio: '1:1',
                label:'',
                buttonCancelLabel: 'Cancelar',
                buttonConfirmLabel: 'ok',
                buttonUploadLabel: 'Ok',
            },
            colors: ["#92D050", "#00B050", "#FFDD53", "#FF9100", "#FF3333", "#C00000", "#FF297E", "#B37BDC", "#803BB4", "#2F5597", "#0090FF", "#A5A5A5",],
        }
    },
    methods: {
        async toggle(item) {
            this.reset()
            if (item) {
                this.titulo = 'EDITAR'
                this.btnModal = 'GUARDAR'
                this.slimOptions.initialImage = item.imagen
                if (item.imagen.includes('https://')) {
                    let img = await this.loadImage(item.imagen)
                    this.$refs.fotoTribu.set_image(`${img}`)
                } else {
                    this.$refs.fotoTribu.instanciaCrop.remove()
                }
                this.model =  {
                    id:item.id,
                    imagen: item.imagen,
                    nombre: item.nombre,
                    clave: item.clave,
                    color: item.color,
                }
            }
            this.$refs.modalCreateTribe.toggle();
        },
        async loadImage(url) {
            var options = {
                method: 'GET',
                headers: { "Cache-Control": 'no-cache' },
            };
            return new Promise((response) => {
                fetch(url, options)
                    .then(function(res) { return res.blob(); })
                    .then(function(blob) {
                        response(URL.createObjectURL(blob));
                    });
            })
        },
        getColor(color) {
            this.model.color = color
        },
        async handleSave() {
            try {
                let archivo = this.$refs.fotoTribu.instanciaCrop.dataBase64.output
                this.model.imagen = archivo.image
                const valid = await this.$refs.formCrearTribu.validate()
                if (this.model.color == '') return
                if(!valid) return
                if(this.model.id == null) await Tribus.crearTribu(this.model);
                else await Tribus.editarTribu(this.model);
                this.$emit('listarTribus')
                this.$refs.modalCreateTribe.toggle();

            } catch (error) {
                const mensaje = error?.response?.data?.error ?? 'error al crear tribu'
                this.notificacion('Error', mensaje, 'error')
            }
        },
        reset() {
            this.model = {
                imagen: '',
                nombre: '',
                clave: '',
                color: '',
            }
            this.titulo = 'CREAR'
            this.btnModal = 'CREAR'
            this.$refs.fotoTribu.instanciaCrop.remove()
        }
    }
}
</script>